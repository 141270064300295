<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Sequence <span>Send Message {{ message.ordering + 1 }} Now</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="edit">
                                    <label for="check" class="checkbox">
                                        <input type="checkbox" id="check" hidden v-model="selectAll" @click="toggleAll">
                                        <span><i class="fas fa-check"></i></span>
                                    </label>
                                </li>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="send-now-filter" />
                                </li>
                                <li>
                                    <input type="text" placeholder="Search" :disabled="sendNowLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                    <i class="fas fa-search"></i>
                                </li>
                                <li class="list_info">
                                    {{ users.from ? users.from : 0 }} - {{ users.to ? users.to : 0 }} of <span>{{ users.total ? users.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(user, u) of users.data" :key="u">
                                    <td>
                                        <label :for="`user-${user.id}`" class="checkbox">
                                            <input type="checkbox" :id="`user-${user.id}`" :value="user.contact_id" v-model="selectedUsers" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </td>
                                    <td>{{ user.contact.name }}</td>
                                    <td>{{ user.contact.email }}</td>
                                </tr>
                                <tr v-if="users.total == 0">
                                    <td colspan="4">No records found!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination pb-4 mt-4">
                        <pagination v-model="params.page" :pages="users.last_page" @update:modelValue="handlePagination" />
                    </div>
                    <div class="pb-4">
                        <label for="delay" class="switch_option capsule_btn">
                            <h5>Delays</h5>
                            <input type="checkbox" id="delay" v-model="schedule" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <schedule-tab v-if="schedule" v-model="delay" ref="schedule-component"></schedule-tab>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="sendNowLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                <button type="button" v-if="schedule" :disabled="sendNowLoader" class="btn save_btn" @click="scheduleSequenceMessage()">
                    <i class="fa fa-spinner fa-spin" v-if="sendNowLoader"></i> {{ sendNowLoader ? 'Scheduling' : 'Schedule' }}
                </button>
                <button type="button" v-else :disabled="sendNowLoader" class="btn save_btn" @click="sendSequenceMessage()">
                    <i class="fa fa-spinner fa-spin" v-if="sendNowLoader"></i> {{ sendNowLoader ? 'Sending' : 'Send' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const ScheduleTab = defineAsyncComponent(() => import('@/components/ScheduleTab'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Sequence Send Now',

        data () {
            return {
                selectedUsers: [],
                params: {
                    per_page: 5,
                    page: 1,
                    search: '',
                },
                selectAll: false,
                schedule: 0,
                delay: {},
                isTyping: false,
            };
        },

        props: {
            modelValue: Boolean,
            sequence: Object,
            message: Object,
        },

        emits: ['update:modelValue'],

        components: {
            ScheduleTab,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.params = {
                        per_page: 5,
                        page: 1,
                        search: '',
                        sequence_id: vm.sequence.id,
                    };

                    vm.selectedUsers = [];
                    vm.selectAll = false;
                    vm.getSequenceUser(vm.params);
                }
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getSequenceUser(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getSequenceUser(vm.params);
                        }
                    }
                }
            },
        },

        computed: mapState({
            sendNowLoader: state => state.sequenceModule.sendNowLoader,
            users: state => state.sequenceModule.sequenceUsers,
        }),

        methods: {
            ...mapActions({
                getSequenceUser: 'sequenceModule/getSequenceUser',
                scheduleSequence: 'sequenceModule/scheduleSequence',
                sendSequenceNow: 'sequenceModule/sendSequenceNow',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            toggleAll () {
                const vm = this;

                if (!vm.selectAll) {
                    vm.selectedUsers = vm.filterIndexValue(vm.users.data, 'contact_id');
                } else {
                    vm.selectedUsers = [];
                }
            },

            filterIndexValue (object, index = 'id') {
                const records = [];

                object.forEach((obj) => { records.push(obj[index]) });

                return records;
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getSequenceUser(vm.params);
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['send-now-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const emailFilter = vm.$refs['send-now-filter'];

                if (emailFilter) {
                    emailFilter.dropdown = false;
                }
            },

            scheduleSequenceMessage () {
                const vm = this;

                if (vm.selectedUsers.length) {
                    const component = vm.$refs['schedule-component'];
                    const delay     = component.delay;
                    const options   = Helper.swalConfirmOptions('Are you sure?', 'You want to schedule this sequence message for selected users.', 'Yes');

                    options.preConfirm = function () {
                                            return vm.scheduleSequence({ contacts: vm.selectedUsers, sequence_id: vm.sequence.id, message_id: vm.message.id, delay }).then((result) => {
                                                if (result) {
                                                    vm.closeModal();
                                                    vm.selectedUsers = [];

                                                    Toastr.success('Sequence message has been scheduled successfully for selected users.');
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one sequence user');
                }
            },

            sendSequenceMessage () {
                const vm = this;

                if (vm.selectedUsers.length) {
                    const options   = Helper.swalConfirmOptions('Are you sure?', 'You want to send this sequence message to selected users.', 'Yes');

                    options.preConfirm = function () {
                                            return vm.sendSequenceNow({ contacts: vm.selectedUsers, sequence_id: vm.sequence.id, message_id: vm.message.id }).then((result) => {
                                                if (result) {
                                                    vm.closeModal();
                                                    vm.selectedUsers = [];
                                                    vm.selectAll = false;

                                                    Toastr.success('Sequence message has been queued successfully for selected users.');
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one sequence user');
                }
            },
        },
    }
</script>
